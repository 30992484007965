// src/App.js
import React from 'react';
import Home from './pages/Home';
import './styles/global.css'; // Add global styles

function App() {
  return <Home />;
}

export default App;
