// src/pages/Home.js
import React from 'react';
import Background from '../components/Background';
import BackgroundMusic from '../components/BackgroundMusic';
import Header from '../components/Header';
import HomeSection from '../components/HomeSection';
import Footer from '../components/Footer';

function Home() {
    return (
        <div>
            <BackgroundMusic />
            <Background />
            <Header />
            <HomeSection />
            <Footer />
        </div>
    );
}

export default Home;