import React, { useRef, useEffect } from 'react';

const BackgroundMusic = () => {
    const audioRef = useRef(null);

    useEffect(() => {
        // Add a click event listener to the whole document
        const handleUserClick = () => {
            // Play the audio when the user clicks anywhere on the page
            if (audioRef.current) {
                audioRef.current.play();
            }

            // Remove the event listener after the first click
            document.removeEventListener('click', handleUserClick);
        };

        // Add the event listener
        document.addEventListener('click', handleUserClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleUserClick);
        };
    }, []);

    return (
        <audio ref={audioRef} src="/assets/schizo2.wav" loop />
    );
};

export default BackgroundMusic;
