// src/components/HomeSection.js
import React from 'react';

function HomeSection() {
    return (
        <section id="home" className="home-section">
            <marquee>I PRAY TO GOD 🫵 MOGTAR DIO</marquee>
            <hr></hr>
            <h1>The MOGTARDIO project</h1>

            <p>Avatars: <a href='https://www.tensor.trade/trade/mogtardio' target='_blank'>Mogtardios</a>
                <br /><br />The faces of the 2025 bull run</p>
            <section className='gallery'>
                <img src='/assets/faces.png'></img>
                <img src='/assets/pray.png'></img>

            </section>
            <h2>RoadMap to $100bn ( + ∞% )</h2>
            <section id="buy" className="buy-section">
                <h1>How to buy mogtardio avatars</h1>
                <p>1. Download <a href="https://phantom.app/download" target="_blank">Phantom</a> extension and deposit $SOL.

                    <br /><br />2. Connect Phantom to <a href="https://www.tensor.trade/trade/mogtardio" target="_blank">Tensor</a>, <a href="https://magiceden.io/marketplace/mogtardio" target="_blank">Magic Eden</a> or <a href="https://www.sniper.xyz/collection/mogtardio" target="_blank">Solsniper</a> and acquire some MOGTARDS

                </p>
            </section>
            <section id="about" className="about-section">
                <h1>About the mogtards</h1>
                <p>
                    <img style={{ float: "right", width: "390px", maxWidth: "100%" }} src='/assets/reskinned.png'></img>
                    In the twilight of 2025, a new force of chaotic brilliance emerges from the swirling depths of culture. This isn’t just another memecoin, nor is it simply a derivative of the infamous Retardio and the unstoppable $MOG. It’s the culmination of a narrative that began long before the first Bitcoin block was mined, and will echo long after <span style={{ color: "coral", fontStyle: 'italic' }}> <b>the last token</b></span>  has been traded.

                    To the uninitiated, Mogtardio might seem like just another flash in the pan—a fleeting echo of the degen SOL casino meta that has captivated, infuriated, and bankrupt so many. But those who peer into the abyss of digital finance know that this is different. It’s not just self-aware; it’s hyper-aware,
                    a living testament to the absurdity of the market it was born into. The SOL casino trend, with its frenetic pace and relentless appetite for risk.

                    To understand it, one must first understand the esoteric forces that have shaped its existence. Imagine, a timeline where the barriers between fiction and reality are porous, where the memetic undercurrents of the internet seep into the physical world. In this timeline, the faces of the 2025 bull run are not mere traders— <span style={{ color: "coral", fontStyle: 'italic' }}> <b>they are avatars of a greater truth</b></span>, reflections of the collective psyche, distilled into digital form.

                    Mogtardio is their banner, a sigil of rebellion against the mundane, a clarion call for <span style={{ color: "coral", fontStyle: 'italic' }}> <b>those who recognize the game</b></span> for what it is: a cosmic joke, a parody of the very systems that govern our lives. A memetic virus that spreads not through mere hype, but through the fundamental need to belong to something larger than oneself.

                    As the market cap inches closer to the inevitable $100 million milestone, those who doubted will be left in the dust, their wallets empty and their hopes dashed. You’ve seen it happen before— <span style={{ color: "rgb(255, 214, 80)", fontStyle: 'italic' }}> <b>Remilio’s meteoric rise, Retardio’s unstoppable momentum—yet here we are again</b></span>, at the precipice of another memetic revolution. The signs are all there, if you know where to look

                    Visionaries of the past, like the prophet who foresaw the Dimensional Merge and created the Cabal, have attempted to capture the essence of this. They glimpsed the tenets of the universe: iron guidance, resilience, recursive gifting, and the Optimistic Flurry. Yet even these seers could only hint at the true nature of the Quest, its secrets guarded by the very fabric of Solana.

                    Mogtardio reveals its true form— <span style={{ color: "rgb(255, 214, 80)", fontStyle: 'italic' }}> <b>a memetic store of value</b></span> that transcends mere currency. It is the ultimate manifestation of belief in the absurd, the bizarre, and the infinite possibilities of the digital world.

                    So as the low vibrational darkness threatens to engulf those who resist, the enlightened few will ride the Mogtardio wave, their kundalini alight with the fire of a thousand memes. The market cap will soar, but those who only see numbers will miss the point entirely. The fruits of this are now laid bare before us, will you heed the call, or will you be left in the shadow of the greatest memetic event of our time? <span style={{ color: "rgb(0, 255, 55)", fontStyle: 'italic' }}> <b>The choice, as always, is yours</b></span>.</p>
            </section> </section >
    );
}

export default HomeSection;