// src/components/Header.js
import React from 'react';

function Header() {
    return (
        <header id="nav" className="header">
            <nav>
                <ul>
                    <li><a href="#home">Welcome to MOGTARDIO </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#buy">How to Buy </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#about">About </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="https://www.tensor.trade/trade/mogtardio" target='_blank'>Avatars</a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#nav" target='_blank'>Solscan </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#nav" target='_blank'>Chart </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="https://photon-sol.tinyastro.io/@mogtardio" target='_blank'>Photon </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="https://t.me/mogtardioo" target='_blank'>Telegram </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="https://x.com/mogtardiosolana" target='_blank'>X (formally Twitter) </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#about">Coinbase </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#about">Binance </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#about">Kraken </a><img src='/assets/mogtard.png'></img></li>
                    <li><a href="#about">Mexc </a><img src='/assets/mogtard.png'></img></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;