// src/components/Background.js
import React from 'react';
import './Background.css';

function Background() {
    return (
        <div className="video-background">
            <video autoPlay loop muted playsInline className="video">
                <source src="/assets/background.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default Background;